// frontend/src/utils/api.js

import axios from 'axios';
import { auth } from '../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create an Axios instance
const api = axios.create({
  baseURL: 'https://environment.sh/api', // Replace with your backend URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the ID token
api.interceptors.request.use(
  async (config) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken(true); // Force refresh
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;