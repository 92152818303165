// frontend/src/components/Account.js

import React, { useState } from 'react';
import { auth } from '../firebase';
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  deleteUser,
} from 'firebase/auth';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

function Account() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');

  const handleClose = () => {
    setOpen(false);
    setMessage({ type: '', text: '' });
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDeletePassword('');
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setMessage({ type: 'error', text: 'New passwords do not match.' });
      setOpen(true);
      return;
    }

    const user = auth.currentUser;
    if (user) {
      // Reauthenticate the user
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      try {
        await reauthenticateWithCredential(user, credential);
        // Update the password
        await updatePassword(user, newPassword);
        setMessage({ type: 'success', text: 'Password updated successfully!' });
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } catch (error) {
        setMessage({ type: 'error', text: error.message });
      }
      setOpen(true);
    }
  };

  const handleDeleteAccount = async () => {
    const user = auth.currentUser;
    if (user) {
      // Reauthenticate the user
      const credential = EmailAuthProvider.credential(user.email, deletePassword);
      try {
        await reauthenticateWithCredential(user, credential);
        // Delete the user
        await deleteUser(user);
        setMessage({ type: 'success', text: 'Account deleted successfully!' });
        setDeletePassword('');
        // Redirect to the login page or a goodbye page
        // For example:
        window.location.href = '/';
      } catch (error) {
        setMessage({ type: 'error', text: error.message });
      }
      setOpen(true);
      setDeleteDialogOpen(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 600, margin: 'auto', mt: 5 }}>
      <Typography variant="h5" gutterBottom>
        Account Settings
      </Typography>
      <form onSubmit={handleChangePassword}>
        <Grid container spacing={3}>
          {/* Current Password */}
          <Grid item xs={12}>
            <TextField
              label="Current Password"
              type="password"
              variant="outlined"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          {/* New Password */}
          <Grid item xs={12}>
            <TextField
              label="New Password"
              type="password"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          {/* Confirm New Password */}
          <Grid item xs={12}>
            <TextField
              label="Confirm New Password"
              type="password"
              variant="outlined"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Change Password
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Delete Account Section */}
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Typography variant="h6" color="error">
            Delete Account
          </Typography>
          <Typography variant="body2" color="textSecondary">
            This action is irreversible. All your data will be permanently removed.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={() => setDeleteDialogOpen(true)}
          >
            Delete Account
          </Button>
        </Grid>
      </Grid>

      {/* Delete Account Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-account-dialog-title"
      >
        <DialogTitle id="delete-account-dialog-title">Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your current password to confirm account deletion. This action cannot be undone.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Current Password"
            type="password"
            fullWidth
            variant="outlined"
            value={deletePassword}
            onChange={(e) => setDeletePassword(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Messages */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={message.type === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default Account;
