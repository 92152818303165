// frontend/src/components/VerifyEmail.js

import React, { useState } from 'react';
import { auth } from '../firebase';
import {
  Paper,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import { sendEmailVerification, signOut } from 'firebase/auth';

function VerifyEmail() {
  const [message, setMessage] = useState({ type: '', text: '' });
  const [open, setOpen] = useState(false);

  const handleResendVerification = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setMessage({ type: 'success', text: 'Verification email resent!' });
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
    }
    setOpen(true);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setMessage({ type: 'success', text: 'Signed out successfully.' });
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage({ type: '', text: '' });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
      <Grid item xs={11} sm={8} md={6}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom align="center">
            Verify Your Email Address
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            A verification email has been sent to <strong>{auth.currentUser.email}</strong>. Please check your inbox and follow the instructions to verify your email.
          </Typography>
          <Typography variant="body2" gutterBottom align="center">
            If you didn't receive the email, you can resend it below.
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleResendVerification}
              >
                Resend Verification Email
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={handleSignOut}
              >
                Sign Out
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Snackbar for Messages */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={message.type === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default VerifyEmail;
