// frontend/src/components/Auth.js

import React, { useState } from 'react';
import { auth } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
} from 'firebase/auth';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Box,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import loginImage from '../images/login-image.jpg'; // Import your image here

function Auth() {
  const [isLogin, setIsLogin] = useState(true); // Toggle between Login and Sign Up
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // For Sign Up
  const [message, setMessage] = useState({ type: '', text: '' });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setMessage({ type: '', text: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLogin && password !== confirmPassword) {
      setMessage({ type: 'error', text: 'Passwords do not match.' });
      setOpen(true);
      return;
    }

    try {
      if (isLogin) {
        // Sign In
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
          setMessage({ type: 'error', text: 'Please verify your email before logging in.' });
          setOpen(true);
          await signOut(auth); // Sign out unverified user
          return;
        }
        setMessage({ type: 'success', text: 'Logged in successfully!' });
      } else {
        // Sign Up
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(userCredential.user);
        setMessage({ type: 'success', text: 'Account created! Please verify your email before logging in.' });
      }
      setOpen(true);
      setEmail('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
      setOpen(true);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider(); // Instantiate GoogleAuthProvider
    try {
      const result = await signInWithPopup(auth, provider); // Use signInWithPopup
      // Google accounts are verified by default
      setMessage({ type: 'success', text: 'Logged in with Google successfully!' });
      setOpen(true);
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
      setOpen(true);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setMessage({ type: 'success', text: 'Logged out successfully!' });
      setOpen(true);
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
      setOpen(true);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Top area with website name */}
      {/* Top area with website name */}
<Box
  sx={{
    backgroundColor: 'white',
    py: 2,
    textAlign: 'center',
    borderBottom: '1px solid #e0e0e0',
  }}
>
  <Typography
    variant="h5"
    sx={{
      fontFamily: 'Outfit, sans-serif',
      fontSize: '24px',
      letterSpacing: '3px',
      color: 'black',
      fontWeight: 'bold',
    }}
  >
    ENVIRONMENT
  </Typography>
  <Typography
    variant="subtitle1"
    sx={{
      mt: 1,
      color: '#272727',
    }}
  >
    Discover where your target audience is engaging
  </Typography>
</Box>


      {/* Main content area divided into two halves */}
      <Grid container sx={{ flexGrow: 1 }}>
        {/* Left side with image */}
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box
            sx={{
              height: '100%',
              backgroundImage: `url(${loginImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </Grid>

        {/* Right side with login box */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%', boxShadow: 'none'  }}>
            <Typography variant="h5" gutterBottom>
              {isLogin ? 'Sign In' : 'Sign Up'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* Email Field */}
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                {/* Password Field */}
                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid>
                {/* Confirm Password Field (Only for Sign Up) */}
                {!isLogin && (
                  <Grid item xs={12}>
                    <TextField
                      label="Confirm Password"
                      type="password"
                      variant="outlined"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      fullWidth
                    />
                  </Grid>
                )}
                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    {isLogin ? 'Sign In' : 'Sign Up'}
                  </Button>
                </Grid>
                {/* Toggle Sign In/Sign Up */}
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    color="secondary"
                    fullWidth
                    onClick={() => setIsLogin(!isLogin)}
                  >
                    {isLogin
                      ? "Don't have an account? Sign Up"
                      : 'Already have an account? Sign In'}
                  </Button>
                </Grid>
                {/* Sign Out Button (Only for Sign In) */}
                {isLogin && (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </Button>
                  </Grid>
                )}
                {/* Google Sign-In Button (Only for Sign In) */}
                {isLogin && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      startIcon={<GoogleIcon />}
                      onClick={handleGoogleSignIn}
                    >
                      Sign In with Google
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
            {/* Snackbar for Messages */}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={message.type === 'error' ? 'error' : 'success'}
                sx={{ width: '100%' }}
              >
                {message.text}
              </Alert>
            </Snackbar>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Auth;