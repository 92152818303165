// frontend/src/components/Logout.js

import React, { useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { Navigate } from 'react-router-dom';

function Logout() {
  useEffect(() => {
    const performSignOut = async () => {
      await signOut(auth);
    };
    performSignOut();
  }, []);

  return <Navigate to="/" />;
}

export default Logout;
