// frontend/src/components/Premium.js

import React, { useEffect, useState } from 'react';
import api from '../utils/api'; // Use the shared Axios instance
import {
  Paper,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';

function Premium() {
  const [isPremium, setIsPremium] = useState(false);
  const [email, setEmail] = useState(''); // Added email state
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/user/profile'); // Fetch user profile data
        setIsPremium(response.data.premium);
        setEmail(response.data.email); // Set the email state
      } catch (error) {
        console.error('Error fetching profile:', error);
        setMessage({ type: 'error', text: 'Failed to fetch user profile.' });
        setOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleSubscribe = () => {
    // Construct the payment URL with the user's email prefilled
    const encodedEmail = encodeURIComponent(email);
    const paymentUrl = `https://buy.stripe.com/aEUeUUgJX3Aw8rC147?prefilled_email=${encodedEmail}`;

    // Redirect the user to the payment page
    window.location.href = paymentUrl;
  };

  const handleCancelPremium = async () => {
    try {
      // Send a POST request to the /cancelsub endpoint with the user's email
      await api.post('/cancelsub', { email: email });

      // Update the UI to reflect the cancellation
      setIsPremium(false);
      setMessage({ type: 'success', text: 'Premium subscription cancelled.' });
    } catch (error) {
      console.error('Error cancelling premium:', error);

      // Determine the error message based on the response
      let errorMessage = 'Failed to cancel Premium subscription.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      setMessage({ type: 'error', text: errorMessage });
    } finally {
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setMessage({ type: '', text: '' });
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', mt: 5, px: 2 }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Premium Features Box */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom>
              {isPremium ? 'Premium Features' : 'Upgrade to Premium'}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {isPremium
                ? 'Enjoy the following premium features:'
                : 'Subscribe to access premium features:'}
            </Typography>
            <ul>
              <li>Scan Frequency: <b>Every hour</b></li>
              <li>Maximum Posts Per Day: <b>100</b></li>
              <li>Subreddits: <b>Unlimited</b></li>
              <li>Generate AI Response: <b>Yes</b></li>
            </ul>
            {isPremium ? (
              <Button variant="contained" color="error" onClick={handleCancelPremium} fullWidth>
                Cancel Premium
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleSubscribe} fullWidth>
                Subscribe to Premium
              </Button>
            )}
          </Paper>
        </Grid>

        {/* Free Features Box */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Typography variant="h5" gutterBottom>
              Free Features
            </Typography>
            <ul>
              <li>Scan Frequency: <b>Every 24 hours</b></li>
              <li>Maximum Saved Posts Per Day: <b>5</b></li>
              <li>Subreddits: <b>1</b></li>
              <li>Generate AI Response: <b>No</b></li>
            </ul>
            <Button variant="outlined" color="secondary" disabled fullWidth>
              Included for Free
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Snackbar for Messages */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={message.type === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Premium;
