// frontend/src/components/Configuration.js

import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Typography,
  Grid,
  Paper,
  Box,
} from '@mui/material';

function Configuration() {
  const [companyProfile, setCompanyProfile] = useState('');
  const [subreddits, setSubreddits] = useState('');
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isAutopilotLoading, setIsAutopilotLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [open, setOpen] = useState(false);
  const [premium, setPremium] = useState(false); // Added premium state

  useEffect(() => {
    // Fetch user profile to get premium status
    api.get('/user/profile') // Ensure this endpoint returns the premium status
      .then(response => {
        console.log('User profile data:', response.data); // Debugging
        setPremium(response.data.premium || false);
      })
      .catch(error => {
        console.error('Error fetching user profile:', error);
        setError('Failed to fetch user profile. Please try again.');
      });


    console.log(premium)

    // Fetch existing configuration
    api.get('/configuration/config')
      .then(response => {
        setCompanyProfile(response.data.companyProfile);
        setSubreddits(response.data.subreddits.join(', '));
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching configuration:', error);
        setError('Failed to fetch configuration. Please try again.');
        setLoading(false);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const subredditArray = subreddits.split(',').map(sub => sub.trim()).filter(Boolean);
console.log(subredditArray) 
    // Validation for non-premium users
    if (!premium && subredditArray.length > 1) {
      setError('Free users can only have 1 subreddit. Please remove extra subreddits.');
      return;
    }

    // Update entire configuration in a single request
    api.post('/configuration/config', {
      companyProfile,
      subreddits: subredditArray,
    })
      .then(() => {
        setSuccess(true);
      })
      .catch(error => {
        console.error('Error updating configuration:', error);
        setError('Failed to update configuration. Please try again.');
      });
  };

  const handleAutopilot = async () => {
    setIsAutopilotLoading(true);
    try {
      const response = await api.post('/autopilot', { url: websiteUrl });
      const { companyProfile: autopilotCompanyProfile, subreddits: autopilotSubreddits } = response.data;

      // Adjust subreddits based on premium status
      let subredditArray = autopilotSubreddits;

      // Convert subreddits array to a comma-separated string
      const subredditString = subredditArray.join(', ');

      // Update the company profile and subreddits in the configuration
      setCompanyProfile(autopilotCompanyProfile);
      setSubreddits(subredditString);

      setMessage({ type: 'success', text: 'Autopilot completed successfully! Please review and Save Changes' });
      setOpen(true);
    } catch (error) {
      console.error('Autopilot error:', error);
      setMessage({ type: 'error', text: 'Autopilot failed. Please try again.' });
      setOpen(true);
    }
    setIsAutopilotLoading(false);
  };

  const handleClose = () => {
    setSuccess(false);
    setError(null);
    setOpen(false);
    setMessage({ type: '', text: '' });
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Autopilot
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              label="Website URL"
              variant="outlined"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!websiteUrl || isAutopilotLoading}
              onClick={handleAutopilot}
            >
              {isAutopilotLoading ? 'Analyzing...' : 'Run Autopilot'}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Manual Configuration
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Company Profile"
                multiline
                rows={4}
                variant="outlined"
                value={companyProfile}
                onChange={(e) => setCompanyProfile(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Subreddits to Track (comma separated)"
                variant="outlined"
                value={subreddits}
                onChange={(e) => setSubreddits(e.target.value)}
                required
                fullWidth
              />
              {!premium && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  Note: Free users can only add 1 subreddit. Upgrade to premium for unlimited subreddits.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Save Configuration
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* Success Snackbar */}
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Configuration updated successfully!
          </Alert>
        </Snackbar>
        {/* Error Snackbar */}
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
        {/* Autopilot Message Snackbar */}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.type} sx={{ width: '100%' }}>
            {message.text}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
}

export default Configuration;
