import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'; // Import Link as RouterLink
import api from '../utils/api';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Snackbar,
  Alert,
  Typography,
  CircularProgress,
  Link,
  Box,
} from '@mui/material';

function Analytics() {
  const [posts, setPosts] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(true); // New state for profile loading
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [profile, setProfile] = useState(null); // New state for user profile
  const [nextScanTime, setNextScanTime] = useState(null); // New state for next scan time

  useEffect(() => {
    // Fetch posts
    const fetchPosts = api
      .get('/analytics/posts')
      .then((response) => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
        setError('Failed to fetch posts. Please try again.');
        setLoading(false);
      });

    // Fetch user profile
    const fetchProfile = api
      .get('/analytics/profile')
      .then((response) => {
        setProfile(response.data);
        setProfileLoading(false);
        calculateNextScan(response.data.lastScanTime, response.data.premium);
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        setError('Failed to fetch user profile. Please try again.');
        setProfileLoading(false);
      });

    Promise.all([fetchPosts, fetchProfile]);
  }, []);

  // Function to calculate next scan time
  const calculateNextScan = (lastScanTime, premium) => {
    const scanIntervalHours = premium ? 1 : 24;
    let nextScanDate;

    if (lastScanTime) {
      nextScanDate = new Date(lastScanTime);
      nextScanDate.setHours(nextScanDate.getHours() + scanIntervalHours);
    } else {
      // If lastScanTime is not set, set next scan to 10 minutes from now
      nextScanDate = new Date();
      nextScanDate.setMinutes(nextScanDate.getMinutes() + 10);
    }

    setNextScanTime(nextScanDate);
  };

  // Function to get remaining time
  const getRemainingTime = () => {

    if (!nextScanTime) return null;

    const now = new Date();
    const difference = nextScanTime - now;

    if (difference <= 0) {
      return 'Scan is due now.';
    }

    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return `Next scan in ${hours} hour(s) and ${minutes} minute(s).`;
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = posts.map((post) => post._id);
      setSelectedPosts(newSelecteds);
      return;
    }
    setSelectedPosts([]);
  };

  const handleDelete = () => {
    api
      .delete('/analytics/posts', { data: { ids: selectedPosts } })
      .then(() => {
        setPosts(posts.filter((post) => !selectedPosts.includes(post._id)));
        setSelectedPosts([]);
        setSuccess('Selected posts deleted successfully.');
      })
      .catch((error) => {
        console.error('Error deleting posts:', error);
        setError('Failed to delete posts. Please try again.');
      });
  };

  const handleGenerateResponse = (postId) => {
    api
      .post('/analytics/generate-response', { postId })
      .then((response) => {
        alert(response.data.response);
      })
      .catch((error) => {
        console.error('Error generating response:', error);
        setError('Failed to generate response. Please try again.');
      });
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
  };

  if (loading || profileLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 4 }}>
      <Typography variant="h5" gutterBottom>
        Analytics
      </Typography>

      {/* Display Profile Completion Message */}
      {profile && profile.companyProfile && profile.companyProfile.length < 3 && (
        <Typography variant="body1" color="error" sx={{ mb: 2 }}>
          Please complete your profile first.{' '}
          <Link component={RouterLink} to="/configuration" color="primary">
            Go to Configuration
          </Link>
        </Typography>
      )}

      {/* Display Next Scan Time and Upgrade Prompt */}
      {nextScanTime && (
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          {getRemainingTime()}{' '}
          {profile && !profile.premium && (
            <Link component={RouterLink} to="/premium" color="primary">
              Upgrade to Premium for more up-to-date results.
            </Link>
          )}
        </Typography>
      )}

      <Button
        variant="contained"
        color="secondary"
        onClick={handleDelete}
        disabled={selectedPosts.length === 0}
        sx={{ mb: 2 }}
      >
        Delete Selected
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedPosts.length > 0 && selectedPosts.length < posts.length
                  }
                  checked={posts.length > 0 && selectedPosts.length === posts.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Title</TableCell>
              <TableCell sx={{ maxWidth: 300 }}>Content</TableCell> {/* Set max-width here */}
              <TableCell>Actions</TableCell>
              <TableCell>Date Added</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post) => {
              const isItemSelected = selectedPosts.includes(post._id);
              return (
                <TableRow key={post._id} selected={isItemSelected}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSelectedPosts([...selectedPosts, post._id]);
                        } else {
                          setSelectedPosts(selectedPosts.filter((id) => id !== post._id));
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{post.source}</TableCell>
                  <TableCell>
                    {post.title.length > 50
                      ? `${post.title.substring(0, 50)}...`
                      : post.title}
                  </TableCell>
                  <TableCell sx={{ maxWidth: 300 }}>
                    {post.body
                      ? post.body.length > 100
                        ? `${post.body.substring(0, 100)}...`
                        : post.body
                      : 'No Content'}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(post.link, '_blank')}
                      sx={{ mr: 1, mb: { xs: 1, sm: 0 } }}
                    >
                      View
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleGenerateResponse(post._id)}
                      sx={{ mr: 1, mb: { xs: 1, sm: 0 } }}
                    >
                      Generate Response
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        api
                          .delete('/analytics/posts', { data: { ids: [post._id] } })
                          .then(() => {
                            setPosts(posts.filter((p) => p._id !== post._id));
                            setSuccess('Post deleted successfully.');
                          })
                          .catch((error) => {
                            console.error('Error deleting post:', error);
                            setError('Failed to delete the post. Please try again.');
                          });
                      }}
                      sx={{ mb: { xs: 1, sm: 0 } }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                  <TableCell>{new Date(post.dateAdded).toLocaleString()}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Success Snackbar */}
      <Snackbar open={Boolean(success)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
      {/* Error Snackbar */}
      <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default Analytics;
