// App.js

import React, { useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  Navigate,
} from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Analytics as AnalyticsIcon,
  Settings as SettingsIcon,
  AccountCircle as AccountIcon,
  Star as PremiumIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import Analytics from './components/Analytics';
import Configuration from './components/Configuration';
import Account from './components/Account';
import Premium from './components/Premium';
import Logout from './components/Logout';
import VerifyEmail from './components/VerifyEmail';
import Auth from './components/Auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import api from './utils/api'; // Axios instance with interceptor
// Create a custom theme with 'Outfit' font
const theme = createTheme({
  palette: {
    primary: {
      main: '#131d45', // Customize your primary color
    },
    secondary: {
      main: '#283a7f', // Customize your secondary color
    },
  },
  typography: {
    fontFamily: 'Outfit, sans-serif', // Set 'Outfit' as the default font
    h5: {
      fontWeight: 600,
    },
  },
});

// Helper component to integrate Link with Tab
function LinkTab(props) {
  return <Tab component={Link} {...props} />;
}



// Main Application Component (Protected Routes)
function MainApp() {
  const location = useLocation();
  const [value, setValue] = React.useState(() => {
    switch (location.pathname) {
      case '/analytics':
        return 0;
      case '/configuration':
        return 1;
      case '/account':
        return 2;
      case '/premium':
        return 3;
      case '/logout':
        return 4;
      default:
        return 0;
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Update the tab index when the route changes
    switch (location.pathname) {
      case '/analytics':
        setValue(0);
        break;
      case '/configuration':
        setValue(1);
        break;
      case '/account':
        setValue(2);
        break;
      case '/premium':
        setValue(3);
        break;
      case '/logout':
        setValue(4);
        break;
      default:
        setValue(0);
    }
  }, [location.pathname]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
        {' '}
          <Typography variant="h7" sx={{ ml: 2, fontWeight: 500, letterSpacing: '3px', flexGrow:1 }}>
            {' '}ENVIRONMENT
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <LinkTab
              label="Analytics"
              to="/analytics"
              icon={<AnalyticsIcon />}
              iconPosition="start"
            />
            <LinkTab
              label="Configuration"
              to="/configuration"
              icon={<SettingsIcon />}
              iconPosition="start"
            />
            <LinkTab
              label="Account"
              to="/account"
              icon={<AccountIcon />}
              iconPosition="start"
            />
            <LinkTab
              label="Premium"
              to="/premium"
              icon={<PremiumIcon />}
              iconPosition="start"
            />
            <LinkTab
              label="Logout"
              to="/logout"
              icon={<LogoutIcon />}
              iconPosition="start"
            />
          </Tabs>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 3 }}>
        <Routes>
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/account" element={<Account />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Navigate to="/analytics" />} /> {/* Default Route */}
        </Routes>
      </Box>
    </Box>
  );
}

function App() {
  // Use the useAuthState hook to get the current user, loading, and error states
  const [user, loading, error] = useAuthState(auth);
  const [message, setMessage] = React.useState({ type: '', text: '' });
  const [open, setOpen] = React.useState(false);
  const hasInitializedProfile = useRef(false); // To prevent multiple initializations

  const handleClose = () => {
    setOpen(false);
    setMessage({ type: '', text: '' });
  };

  useEffect(() => {
    const initializeProfile = async () => {
      if (user && user.emailVerified && !hasInitializedProfile.current) {
        try {
          // Initialize the ClientProfile by calling the backend endpoint
          const response = await api.post('/user/init-profile');
          console.log(response.data.message);
          setMessage({ type: 'success', text: response.data.message });
          setOpen(true);
          hasInitializedProfile.current = true; // Mark as initialized
        } catch (initError) {
          console.error('Error initializing profile:', initError);
          setMessage({ type: 'error', text: 'Error initializing profile.' });
          setOpen(true);
        }
      }
    };

    initializeProfile();
  }, [user]);

  useEffect(() => {
    if (error) {
      setMessage({ type: 'error', text: error.message });
      setOpen(true);
    }
  }, [error]);

  if (loading) {
    // While checking auth state, show a loading spinner
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {user ? (
          user.emailVerified ? (
            <MainApp /> // If user is authenticated and email is verified, show the main app
          ) : (
            <VerifyEmail /> // If user is authenticated but email is not verified, show VerifyEmail component
          )
        ) : (
          <Routes>
            <Route path="*" element={<Auth />} /> {/* If not, show the Auth component */}
          </Routes>
        )}
      </Router>
      {/* Snackbar for Messages */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={message.type === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
